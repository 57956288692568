import React from "react";
import { Link } from "gatsby";

// CSS imports
import "../../assets/sass/footer.scss";

export default class AppFooter extends React.Component {
// icon ion-social-twitter fa-facebook
  render() {
    let footerClass = "footer-basic footer py-1"  
    if (this.props.positioning === "fixed") {
     footerClass =  footerClass + " footer-fixed"    
    } 
    let userGuideComponent = "";
    if (this.props.userGuide) {
      userGuideComponent = (
        <li className="list-inline-item">
          <Link
            to={this.props.userGuide}
            className="footer-link text-uppercase"
          >
            User Guide
          </Link>
        </li>
      );
    }

    let privacyPolicyComponent = "";
    if (this.props.userGuide) {
      privacyPolicyComponent = (
        <li className="list-inline-item">
          <Link
            to={this.props.privacyPolicy}
            className="footer-link text-uppercase"
          >
            Privacy Policy
          </Link>
        </li>
      );
    }


    let termsOfUseComponent = ""
    if (this.props.termsOfUse) {
      termsOfUseComponent = <li className="list-inline-item">
              <Link
                to={this.props.termsOfUse}
                className="footer-link text-uppercase"
              >
                Terms of Use
              </Link>
            </li>;
    }
    return (
      <div className={footerClass}>
        <footer>
          <ul className="list-inline">
            {userGuideComponent}
            {privacyPolicyComponent}
            {termsOfUseComponent}
          </ul>
          <p className="copyright py-1 m-0">
            <Link to="/" className="footer-home-link">
              TalonStrike Software Solutions © 2019
            </Link>
          </p>
        </footer>
      </div>
    );
  }
}
