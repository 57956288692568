import React from "react";
import { Link } from "gatsby";

import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  //  NavLink,
  Container,
  Collapse
} from "reactstrap";

// CSS imports
import "../../assets/sass/navbar.scss";

// Image imports
import Logo from "../../assets/img/Eagle_White_Fill.svg";

export default class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  buildNavItem = (toPage, title) => {
    if (!toPage) {
      return "";
    }
    return (
      <NavItem>
        <Link to={toPage} className="nav-link text-uppercase">
          {title}
        </Link>
      </NavItem>
    );
  };

  render() {
    let homeLink = this.buildNavItem(this.props.homePage, "Home");
    let faqLink = this.buildNavItem(this.props.faqPage, "Faq");
    let newsLink = this.buildNavItem(this.props.newsPage, "News");
    let pressLink = this.buildNavItem(this.props.pressPage, "Press");
    return (
      <Navbar
        className="navbar-tss bg-dark fixed-top"
        color="light"
        light={true}
        expand="md"
        fixed="true"
      >
        <Container>
          <NavbarBrand href="/">
            <img
              src={Logo}
              alt="Logo"
              className="brand-logo align-middle"
            />
            <span className="app-brand-name align-middle px-3">
              <Link to={this.props.homePage} className="app-brand-name">
                {this.props.appName}
              </Link>
            </span>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {homeLink}
              {faqLink}
              {newsLink}
              {pressLink}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
