import React from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';

// Component imports
import AppLayout from "../app_pages/AppLayout";

// Image imports

// CSS imports

export default class MyHabitsLayout extends React.Component {
  render() {
    return (
      <AppLayout
        userGuide="my_habits/my_habits_user_guide"
        privacyPolicy="my_habits/my_habits_privacy"
        termsOfUse="my_habits/my_habits_terms"
        homePage="my_habits/my_habits"
        faqPage="my_habits/my_habits_faq"
        newsPage="my_habits/my_habits_news"
//        pressPage="my_habits/my_habits_press"
        footerPositioning="bottom"
        appName="My-Habits"
      >
        {this.props.children}
      </AppLayout>
    );
  }
}
