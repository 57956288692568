import React from "react";

// Component imports
import { Document, Page, Outline } from "react-pdf";

// CSS imports
import "../../assets/sass/app_page.scss";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "../../assets/sass/pdf.scss";

export default class PDFViewer extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
    prevPageEnabled: false,
    nextPageEnabled: false
  };

  onLoadSuccess = ({ numPages }) => {
    console.log("onLoadSuccess: ", numPages);
    let nextPageEnabled = numPages > 1;
    this.setState({ numPages: numPages, nextPageEnabled: nextPageEnabled });
  };

  onSourceSuccess = data => {
    console.log("onSourceSuccess", data);
  };

  isPrevEnabled = (pageNumber) => {
    if (pageNumber <= 1) {
      return false
    }
    return true;
  }
  onItemClick = ({ pageNumber }) => {
    this.setState({ pageNumber });
  };

  onPrevPage = () => {
    console.log("onPrevPage");
    let pageNumber = this.state.pageNumber - 1;
    let prevPageEnabled = true;
    if (pageNumber <= 1) {
      pageNumber = 1;
      prevPageEnabled = false;
    }
    let nextPageEnabled = false;
    if (pageNumber < this.state.numPages) {
      nextPageEnabled = true;
    }
    this.setState({ pageNumber, nextPageEnabled, prevPageEnabled });
  };

  onNextPage = () => {
    console.log("onNextPage");
    let pageNumber = this.state.pageNumber + 1;
    let nextPageEnabled = true;
    if (pageNumber >= this.state.numPages) {
      pageNumber = this.state.numPages;
      nextPageEnabled = false;
    }
    let prevPageEnabled = false;
    if (pageNumber > 1) {
      prevPageEnabled = true;
    }
    this.setState({ pageNumber, nextPageEnabled, prevPageEnabled });
  };

  render() {
    const {
      pageNumber,
      numPages,
      prevPageEnabled,
      nextPageEnabled
    } = this.state;
    const prevButtonClass = prevPageEnabled
      ? "btn btn-link tss-btn-link text-lg-right"
      : "btn btn-link tss-btn-link text-lg-right disabled";
    const nextButtonClass = nextPageEnabled
      ? "btn btn-link tss-btn-link text-lg-right"
      : "btn btn-link tss-btn-link text-lg-right disabled";
//    const fileName = `${this.props.fileName}.pdf`;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="text-white h2 text-md-center pdf-document-title">
              {this.props.title}
            </p>
          </div>
        </div>
        <Document
          className="pdf-document row"
          file={this.props.fileURL}
          onLoadSuccess={this.onLoadSuccess}
          onSourceSuccess={this.onSourceSuccess}
          renderMode="canvas"
          onItemClick={this.onItemClick}
        >
          <Outline
            onItemClick={this.onItemClick}
            onParseError={this.onOutlineParseError}
            onParseSuccess={this.onOutlineParseSuccess}
            className="col-lg-3"
          />
          <div className="justify-content-center">
            <Page pageNumber={pageNumber}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <button
                        className={prevButtonClass}
                        onClick={this.onPrevPage}
                      >
                        Prev
                      </button>
                      <span className="mx-2 page-display align-middle">
                        Page {pageNumber} of {numPages}
                      </span>
                      <button
                        className={nextButtonClass}
                        onClick={this.onNextPage}
                      >
                        Next
                      </button>
                    </p>
                  </div>
                  <div className="col-md-6 download-link align-middle">
                    {/* <a
                      href={this.props.fileURL}
                      className="text-right float-right"
                      download={fileName}
                    >
                      Download
                    </a> */}
                  </div>
                </div>
              </div>
            </Page>
          </div>
        </Document>
      </div>
    );
  }
}
