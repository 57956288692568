import React from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';

// Component imports
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

// Image imports

// CSS imports

export default class AppLayout extends React.Component {
  render() {
    var footerPositioning = "bottom";
    if (this.props.footerPositioning) {
      footerPositioning = this.props.footerPositioning;
    }
    return (
      <div>
        <AppHeader 
          homePage={this.props.homePage}
          faqPage={this.props.faqPage}
          newsPage={this.props.newsPage}
          pressPage={this.props.pressPage}
          appName={this.props.appName}
        />
        {this.props.children}
        <AppFooter 
          positioning={footerPositioning} 
          userGuide={this.props.userGuide}
          privacyPolicy={this.props.privacyPolicy}
          termsOfUse={this.props.termsOfUse}
          />
      </div>
    );
  }
};
