import React from "react";
import { graphql } from "gatsby";

// Component imports
import MyHabitsLayout from "../../components/my_habits/MyHabitsLayout";
import PDFViewer from "../../components/common/PDFViewer";
//import ComingSoon from "../../components/common/ComingSoon"
import SEO from "../../components/common/seo";

// CSS imports
import "../../assets/sass/app_page.scss";

export default class MyHabitsUserGuide extends React.Component {
  render() {
    const {
      publicURL: fileURL,
      name: fileName
    } = this.props.data.allFile.edges[0].node;
    return (
      <MyHabitsLayout footerPositioning="bottom">
        <SEO
          title="My-Habits User Guide"
          keywords={[`myhabits`, `my-habits`, "user guide"]}
        />
        {/* <ComingSoon
          title="My-Habits User Guide"
          description="The My-Habits user guide will be released when the app is live on the store"
          backPage="my_habits/my_habits"
          backButtonTitle="Back"
        /> */}
        <PDFViewer
          title="My-Habits User Guide"
          fileName={fileName}
          fileURL={fileURL}
        />
      </MyHabitsLayout>
    );
  }
}

export const query = graphql`
  {
    allFile(
      filter: {
        extension: { eq: "pdf" }
        name: { eq: "My-Habits_User_Guide_Outlined" }
      }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;
